import React, {useContext, useEffect, useState} from "react";
import {Badge, Button, ButtonGroup, Card, FormControl, InputGroup} from "react-bootstrap";
import RelativeTime from "../../components/RelativeTime";
import {Link, useLocation} from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import {ChatTextFill, Search} from "react-bootstrap-icons";
import Pager from "../../components/Pager";
import {SettingsContext} from "../../components/SettingsContext";
import Loading from "../../components/Loading";
import CallCenter from "../../CallCenter";

const IssuesList = ({projectId}) => {
    const [totals, setTotals] = useState({
        pages: 1,
        issues: 0,
        open: 0,
        closed: 0,
    });
    const [filterSettings, setFilterSettings] = useState({
        page: 1,
        order: 'time_created',
        search: '',
        state: 'open',
        initiated: false,
    });
    const [issues, setIssues] = useState([]);
    const [loading, setLoading] = useState(true);


    const location = useLocation();
    const {getIssueSettings, setIssueSettings} = useContext(SettingsContext);

    const handlePageChange = (page) => {
        setFilterSettings({...filterSettings, page});
    };

    const [prevDependencies, setPrevDependencies] = useState({
        page: 1,
        search: '',
        state: 'open',
        order: 'time_created',
        initiated: false,
    });

    useEffect(() => {
        if (prevDependencies.page !== filterSettings.page ||
            prevDependencies.search !== filterSettings.search ||
            prevDependencies.state !== filterSettings.state ||
            prevDependencies.order !== filterSettings.order ||
            prevDependencies.initiated !== filterSettings.initiated) {
            setIssueSettings(location.key, filterSettings);
            setLoading(true);
            CallCenter.get('issues', {...filterSettings, projectId}).then(response => {
                if (response?.data?.issues) {
                    setIssues(response.data.issues.map(issue => {
                        if (issue.time_created) {
                            issue.time_created = new Date(issue.time_created);
                        }
                        if (issue.time_updated) {
                            issue.time_updated = new Date(issue.time_updated);
                        }
                        return issue;
                    }));
                }
                if (response?.data?.pages) {
                    setTotals({
                        pages: response.data.pages,
                        issues: response.data.total_issues,
                        open: response.data.total_open,
                        closed: response.data.total_closed,
                    });
                }
                setLoading(false);
            });
        }
        setPrevDependencies(filterSettings);

    }, [filterSettings, issues, projectId]);

    useEffect(() => {
        if (location.state?.force_fs) {
            setFilterSettings(location.state.force_fs);
        } else if (location.key) {
            const _x = getIssueSettings(location.key);
            setFilterSettings(_x);
        } else {
            setFilterSettings({page: 1, state: 'open', search: '', order: 'time_created', initiated: true});
        }
    }, []);

    let c;
    return (
        loading ? <Loading/> : <>
            <div className={`d-flex align-items-start mb-3`}>
                <ButtonGroup size={`sm`}>
                    <Button
                        className={`text-nowrap`}
                        variant={`outline-secondary`}
                        active={filterSettings.state === "open"}
                        onClick={() => setFilterSettings({...filterSettings, state: "open"})}
                    >
                        Atvērti
                        {totals.open > 0 ? (
                            <Badge
                                className={`bg-light text-danger ms-2`}
                                style={{"--bs-badge-font-size": "0.7em"}}
                            >
                                {totals.open}
                            </Badge>
                        ) : ""}
                    </Button>
                    <Button
                        className={`text-nowrap`}
                        variant={`outline-secondary`}
                        active={filterSettings.state === "closed"}
                        onClick={() => setFilterSettings({...filterSettings, state: "closed"})}
                    >
                        Slēgti
                        {totals.closed > 0 ? (
                            <Badge
                                className={`bg-light text-success ms-2`}
                                style={{"--bs-badge-font-size": "0.7em"}}
                            >
                                {totals.closed}
                            </Badge>
                        ) : ""}
                    </Button>
                    <Button
                        className={`text-nowrap`}
                        variant={`outline-secondary`}
                        active={filterSettings.state === "all"}
                        onClick={() => setFilterSettings({...filterSettings, state: "all"})}
                    >
                        Visi
                        {totals.issues ? (
                            <Badge
                                className={`bg-light text-secondary ms-2`}
                                style={{"--bs-badge-font-size": "0.7em"}}
                            >
                                {totals.issues}
                            </Badge>
                        ) : ""}
                    </Button>
                </ButtonGroup>
                <Dropdown className={`ms-3`}>
                    <Dropdown.Toggle variant={`outline-secondary`} size={`sm`}>
                        Kārtot pēc: {{
                        time_created: "pievienošanas laika",
                        time_updated: "aktivitātes"
                    }[filterSettings.order]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setFilterSettings({...filterSettings, order: "time_updated"})}>
                            aktivitātes
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setFilterSettings({...filterSettings, order: "time_created"})}>
                            pievienošanas laika
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <InputGroup size={`sm`} className={`mx-3`}>
                    <FormControl
                        type="text"
                        placeholder="meklē.."
                        defaultValue={filterSettings.search}
                        onKeyUp={e => (e.keyCode === 13 || e.key === 'Enter') && setFilterSettings({
                            ...filterSettings,
                            search: e.target.value
                        })}
                    />
                    <Button size={`sm`} variant={`outline-secondary`} onClick={e => setFilterSettings({
                        ...filterSettings,
                        search: e.target.closest('.input-group').querySelector('input[type="text"]').value
                    })}>
                        <Search/>
                    </Button>
                </InputGroup>
                <Button as={Link} to={`/issues/new?projectId=${projectId}`} state={{ilfs: filterSettings}} variant={`outline-primary ms-auto`} size={`sm`}>
                    Pievienot
                </Button>
            </div>
            <div className={`mb-3`}>
                {issues.map((issue) => (
                    <Link
                        key={issue.id}
                        to={`/issues/${issue.id}?project_id=${projectId}`}
                        style={{textDecoration: "unset"}}
                        state={{issue: issue, ilfs: filterSettings}}
                    >
                        <Card className="mb-2 position-relative d-flex">
                            <Card.Body>
                                <Card.Title className={`me-2 mb-0`}>{issue.title}</Card.Title>
                            </Card.Body>
                            <Card.Footer className={`d-flex align-items-center`}>
                                <Card.Text
                                    className={`text-muted mb-0`}
                                    style={{fontSize: "0.8rem"}}
                                >
                                    <strong>{issue.author}</strong> izveidoja{" "}
                                    <RelativeTime time={issue.time_created}/>
                                    {!projectId && (
                                        <>
                                            projektā <strong>{issue.projectTitle}</strong>
                                        </>
                                    )}
                                </Card.Text>
                                <div>
                                    {issue.tags.map((tag) => {
                                        let bg;
                                        let fg;
                                        switch (tag) {
                                            case "kļūda":
                                            case "gaida atbildi":
                                                bg = "orange";
                                                fg = "light";
                                                break;
                                            case "atrisināts":
                                                bg = "success";
                                                fg = "light";
                                                break;
                                            case "papildinājums":
                                                bg = "primary";
                                                fg = "light";
                                                break;
                                            case "steidzami":
                                                bg = "danger";
                                                fg = "light";
                                                break;
                                            case "jautājums":
                                                bg = "info";
                                                fg = "light";
                                                break;
                                            default:
                                                bg = "light";
                                                fg = "secondary";
                                                break;
                                        }
                                        return (
                                            <Badge
                                                key={tag}
                                                className={`ms-2 bg-${bg} text-${fg}`}
                                            >
                                                <small style={{fontWeight: "normal"}}>{tag}</small>
                                            </Badge>
                                        );
                                    })}
                                </div>
                                <div className={`stats d-flex gap-2 ms-auto`}>
                                    <div className={`d-flex align-items-center gap-1 lh-1`}>
                                        {issue.stats.comments} <ChatTextFill/>
                                    </div>
                                </div>
                            </Card.Footer>
                        </Card>
                    </Link>
                ))}
            </div>
            <Pager totalPages={totals.pages} page={filterSettings.page} onChangePage={handlePageChange} className={`mb-3`}/>
        </>
    );
};

export default IssuesList;
