import Loading from "../../components/Loading";
import React, {useEffect, useState} from "react";
import {Badge, Button, Container, DropdownMenu, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useLocation, useMatch, useSearchParams} from "react-router-dom";
import CallCenter from "../../CallCenter";
import RelativeTime from "../../components/RelativeTime";
import convertUrlsToLinks from "../../Utilities/convertUrlsToLinks";
import {EyeSlashFill, ThreeDotsVertical} from "react-bootstrap-icons";
import Dropdown from "react-bootstrap/Dropdown";

export default function Issue() {

    let issueId;
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [issue, setIssue] = useState({});
    const [searchParams] = useSearchParams();

    let match = useMatch('/issues/:issueId');
    if (match) {
        issueId = match.params.issueId;
    }
    useEffect(() => {
        CallCenter.get('issue/' + issueId).then(response => {
            const _i = response.data;
            if (_i.time_created) {
                _i.time_created = new Date(_i.time_created);
            }
            if (_i.time_updated) {
                _i.time_updated = new Date(_i.time_updated);
            }
            setIssue(_i);
            setLoading(false);
        });
    }, [issueId, setLoading, setIssue]);

    return loading ? <Loading/> : <Container>
        <div className={`issue-header d-flex align-items-flex-start justify-content-between gap-2`}>
            <h1 className={`fs-4 mb-2`}>{issue.title}</h1>
            <div className={`actions d-flex align-items-start gap-2`}>
                <OverlayTrigger placement="bottom" overlay={(props) => (
                    <Tooltip {...props}>Labot nosaukumu un aprakstu</Tooltip>)}>
                    <Button variant={`outline-secondary`}>Labot</Button>
                </OverlayTrigger>
                <Dropdown>
                    <Dropdown.Toggle variant={`outline-secondary border-transparent shadow-0 no-caret`}><ThreeDotsVertical/></Dropdown.Toggle>
                    <Dropdown.Menu align={`end`}>
                        <Dropdown.Item>Slēgt problēmu</Dropdown.Item>
                        <Dropdown.Item>Jauna saistīta problēma</Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item>Dzēst problēmu</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
        <div className={`issue-meta`}>
            <div className={`issue-meta-state`}>
                {issue.state === 'open' ?
                    <Badge bg={`success-subtle`} text={`success`} className={`border border-success`}>Atvērta</Badge> :
                    <Badge bg={`secondary-subtle`} text={`dark`} className={`border border-secondary`}>Slēgta</Badge>}
            </div>
            <div className={`issue-meta-state`}>
                {issue.is_internal ? <OverlayTrigger placement="bottom" overlay={(props) => (
                    <Tooltip {...props}>Konfidenciāls ieraksts. Redzams tikai
                        adminiem.</Tooltip>)}><Badge bg={`warning-subtle`} text={`warning`} className={`border border-warning`}><EyeSlashFill/></Badge></OverlayTrigger> : ""}
            </div>
            <div className={`issue-meta-author text-body-secondary d-flex align-items-center gap-1`}>Izveidoja <RelativeTime time={issue.time_created}/>
                <Button variant={`link`} className={`link-primary`}>{issue.author}</Button></div>
        </div>
        <div className={`issue-body mt-3`}>
            <div dangerouslySetInnerHTML={{__html: convertUrlsToLinks(issue.description)}}></div>
            <div>Tagi:
                {issue.tags.map((tag) => {
                    let bg;
                    let fg;
                    switch (tag) {
                        case "kļūda":
                        case "gaida atbildi":
                            bg = "orange";
                            fg = "light";
                            break;
                        case "atrisināts":
                            bg = "success";
                            fg = "light";
                            break;
                        case "papildinājums":
                            bg = "primary";
                            fg = "light";
                            break;
                        case "steidzami":
                            bg = "danger";
                            fg = "light";
                            break;
                        case "jautājums":
                            bg = "info";
                            fg = "light";
                            break;
                        default:
                            bg = "light";
                            fg = "secondary";
                            break;
                    }
                    return (
                        <Badge
                            key={tag}
                            className={`ms-2 bg-${bg} text-${fg}`}
                        >
                            <small style={{fontWeight: "normal"}}>{tag}</small>
                        </Badge>
                    );
                })}
            </div>
        </div>
        <hr/>
    </Container>;
}
