import Loading from "../../components/Loading";
import React, {useContext, useEffect, useState} from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {UserContext} from "../users/UserContext";
import CallCenter from "../../CallCenter";


export default function Account() {
    const [loading, setLoading] = useState(true);
    const [saveButtonActive, setSaveButtonActive] = useState(true);
    const [response, setResponse] = useState({
        error: false,
        message: '',
        show: false,
    });

    const [emailValue, setEmailValue] = useState('');
    const [nameValue, setNameValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [newPasswordValue, setNewPasswordValue] = useState('');
    const [newPasswordConfirmValue, setNewPasswordConfirmValue] = useState('');

    const {user, setUser} = useContext(UserContext);


    useEffect(() => {
        setLoading(false);
        setEmailValue(user.email || '');
        setNameValue(user.display_name || '');
    }, [user]);

    const saveAccountInfo = () => {
        setSaveButtonActive(false);
        setResponse({
            error: false,
            message: '',
            show: false,
        });
        const data = {
            display_name: nameValue,
            password: passwordValue,
            new_password: newPasswordValue,
        };
        CallCenter.post('accounts/update_self', data).then(response => {
            console.log(response);
            setSaveButtonActive(true);
            if (response.success === false) {
                setResponse({
                    error: true,
                    message: response.error,
                    show: true,
                });
            } else {
                setResponse({
                    error: false,
                    message: 'Uzstādījumi ir veiksmīgi saglabāti.',
                    show: true,
                });
                setPasswordValue('');
                setNewPasswordValue('');
                setNewPasswordConfirmValue('');
                setUser(response.data.user);
                CallCenter.setUserObject(response.data.user)
            }
        });
    };

    return loading ? <Loading/> : <Container id="projects-container">
        <Row>
            <Col md={3} style={{borderRight: '1px solid var(--bs-secondary)'}}>
                <ul className={`flex-column nav subnav`}>
                    <li className={`nav-item`}>
                        <Link to="/settings/account" className={`nav-link active`}>Konts</Link>
                    </li>
                </ul>
            </Col>
            <Col md={9}>
                <h2>Konta uzstādījumi</h2>
                <Form>
                    <Form.Group className={`mb-2`}>
                        <Form.Label htmlFor={`name`} column={false}>Vārds, uzvārds:</Form.Label>
                        <Form.Control id={`name`} type="text" value={nameValue} onChange={e => setNameValue(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className={`mb-2`}>
                        <Form.Label htmlFor={`email`} column={false}>E-pasta adrese:</Form.Label>
                        <Form.Control id={`email`} type="email" readOnly={true} autoComplete={`username`} value={emailValue} onChange={e => setEmailValue(e.target.value)}/>
                    </Form.Group>

                    <h3 className={`mt-5`}>Paroles maiņa</h3>
                    <div className={`mb-2`}>
                        <Form.Text>Zemāk esošos lauciņus aizpildīt tikai gadījumā, ja nepieciešama paroles nomaiņa.</Form.Text>
                    </div>
                    <Form.Group className={`mb-2`}>
                        <Form.Label htmlFor={`password`} column={false}>Šī brīža parole:</Form.Label>
                        <Form.Control id={`password`} type="password" autoComplete="current-password" value={passwordValue} onChange={e => setPasswordValue(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className={`mb-2`}>
                        <Form.Label htmlFor={`new-password`} column={false}>Jaunā parole:</Form.Label>
                        <Form.Control id={`new-password`} type="password" autoComplete={`new-password`} value={newPasswordValue} onChange={e => setNewPasswordValue(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className={`mb-2`}>
                        <Form.Label htmlFor={`new-password-confirm`} column={false}>Jaunā parole atkārtoti:</Form.Label>
                        <Form.Control id={`new-password-confirm`} type="password" autoComplete={`new-password`} value={newPasswordConfirmValue} onChange={e => setNewPasswordConfirmValue(e.target.value)}/>
                    </Form.Group>
                    <div className={`mt-5 d-flex align-items-center`}>
                        <Button variant="outline-primary" type="button" onClick={saveAccountInfo} disabled={!saveButtonActive}>Saglabāt</Button>
                        <div className={['ms-5', 'fw-semibold', (!response.show ? 'd-none' : null), (response.error ? 'text-danger' : 'text-success')].filter(m => m !== null).join(' ')}>{response.message}</div>
                    </div>
                </Form>
            </Col>
        </Row>
    </Container>
}
