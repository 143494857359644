import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Button,
    Card,
    Col,
    Container,
    FormControl,
    FormGroup,
    FormLabel,
    InputGroup,
    Row,
    Spinner,
    Tab,
    Table,
    Tabs
} from "react-bootstrap";
import {Link, useMatch, useNavigate} from "react-router-dom";
import {ThreeDotsVertical} from "react-bootstrap-icons";
import {UserContext} from "../users/UserContext";
import {SettingsContext} from "../../components/SettingsContext";
import CallCenter from "../../CallCenter";
import Loading from "../../components/Loading";
import IssuesList from "../issues/IssuesList";
import {Editor} from "@tinymce/tinymce-react";
import ConfirmModal from "../../components/ConfirmModal";
import Dropdown from "react-bootstrap/Dropdown";


function Project(props) {
    let projectId;
    const navigate = useNavigate();
    const {userCan} = useContext(UserContext);
    const {lastProjectsPage} = useContext(SettingsContext);
    const [key, setKey] = useState('issues');
    const [tickets, setTickets] = useState([]);
    const [showEditWorkEntry, setShowEditWorkEntry] = useState(false);
    const [editWorkEntry, setEditWorkEntry] = useState({
        id: 0,
        title: '',
        started: null,
        ended: null,
        description: ''
    });
    const [workItems, setWorkItems] = useState([]);


    let match = useMatch('/projects/:projectId/:tab');
    useEffect(() => {
        if (match === null) {
            navigate('issues');
        } else {
            setKey(match.params.tab);
            if (match.params.tab === 'work') {
                loadWorkItems();
            }
        }
    }, [match, navigate]);
    if (match) {
        projectId = match.params.projectId;
    }

    const projectIdRef = useRef(null);

    const handleProjectIdClick = (element) => {
        const range = document.createRange();
        range.selectNodeContents(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
    };
    const [projectLogo, setProjectLogo] = useState('');
    const [projectTitle, setProjectTitle] = useState('');
    const [projectClientName, setProjectClientName] = useState('');
    const [projectContent, setProjectContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [workItemSaving, setWorkItemSaving] = useState(false);


    const [confirmModalProps, setConfirmModalProps] = useState({
        show: false,
        title: '',
        message: '',
        handleClose: () => {
        },
        handleConfirm: () => {
        },
    });

    const confirm = (title, message) => new Promise((resolve, reject) => {

    });

    const unixToDatetimeLocal = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000); // Javascript uses milliseconds
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        const hours = ("0" + date.getHours()).slice(-2);
        const minutes = ("0" + date.getMinutes()).slice(-2);
        return [year, month, day].join('-') + 'T' + [hours, minutes].join(':');
    };

    function UTCToLocalTime(timestamp) {
        const date = new Date(timestamp * 1000); // convert Unix timestamp to milliseconds
        const options = {day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric'};
        return new Intl.DateTimeFormat('lv-LV', options).format(date);
    }

    function formatDuration(started, ended) {
        let diffSeconds = ended - started; // elapsed time in seconds
        diffSeconds = Math.round(diffSeconds / 900) * 900; // round to nearest 15 minutes
        let h = Math.floor(diffSeconds / 3600);
        let m = Math.round((diffSeconds % 3600) / 60); // get minutes and round to nearest minute

        // Conditional formatting
        if (h > 0 && m > 0)
            return `${h}h ${m}m`; // e.g., 1h 30m
        else if (h > 0 && m === 0)
            return `${h}h`; // e.g., 1h
        else
            return `${m}m`; // e.g., 30m
    }


    useEffect(() => {
        // load project data
        if (projectId !== null) {
            CallCenter.get(`project/${projectId}`).then(response => {
                if (response.data) {
                    setProjectContent(response.data.content);
                    setProjectTitle(response.data.title);
                    setProjectContent(response.data.description);
                    setProjectLogo(response.data.logo);
                    if (response.data.client_id) {
                        setProjectClientName(response.data.client_name);
                    }
                }
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [projectId]);

    const toggleEditWorkEntry = (id, item) => {
        if (showEditWorkEntry) {
            setShowEditWorkEntry(false);
        } else {
            setEditWorkEntry(prevState => {
                setShowEditWorkEntry(true);
                return {
                    id,
                    title: '',
                    started: null,
                    ended: null,
                    description: '',
                    initialDescription: '',
                    ...(item ? {...item, initialDescription: item.description} : {})
                };
            });
        }
    };

    const saveWorkEntry = () => {
        setWorkItemSaving(true);
        CallCenter.post(`project/${projectId}/work`, {
            id: editWorkEntry.id,
            title: editWorkEntry.title,
            description: editWorkEntry.description,
            started: editWorkEntry.started,
            ended: editWorkEntry.ended,
        }).then(response => {
            toggleEditWorkEntry();
            setWorkItems(response.data.items);
            setWorkItemSaving(false);
        });
    };

    const deleteWorkItem = () => {
        CallCenter.post(`project/${projectId}/work`, {
            'delete': editWorkEntry.id
        }).then(response => {
            toggleEditWorkEntry();
            setWorkItems(response.data.items);
            setWorkItemSaving(false);
        });
    };

    const loadWorkItems = () => {
        CallCenter.get(`project/${projectId}/work`).then(response => {
            setWorkItems(response.data.items);
        });
    };


    return loading ? <Loading/> : <Container id="projects-container">
        <div className={"d-flex align-items-start"}>
            <div className={`image-icon me-3`}>
                <img src={projectLogo} alt={projectTitle}/>
            </div>
            <div className={`mb-3`}>
                <h1 className={"mb-0"}>{projectTitle}</h1>
                {userCan('list_clients') && <h6 className={"text-muted mb-0"}>{projectClientName}</h6>}
            </div>
            {/*<div className={'ms-auto text-right lh-1'}>Projekta ID:<br/>
                <code className={"text-nowrap"} ref={projectIdRef} onClick={() => handleProjectIdClick(projectIdRef.current)}>{projectId}</code>
            </div>*/}
            {userCan('edit_project') &&
                <div className={`d-flex gap-2 ms-auto`}>
                    <Button variant={`outline-secondary`} as={Link} to={`/projects/${projectId}/edit`}>
                        Labot
                    </Button>
                    <Dropdown>
                        <Dropdown.Toggle variant={`outline-secondary border-transparent shadow-0 no-caret`}><ThreeDotsVertical/></Dropdown.Toggle>
                        <Dropdown.Menu align={`end`}>
                            <Dropdown.Item>Dzēst projektu</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            }
        </div>
        <Tabs
            activeKey={key}
            onSelect={(k) => navigate(`/projects/${projectId}/${k}`)}
            className="mb-3"
        >
            {/*            <Tab eventKey="info" title="Par projektu">
                <div className="page-content" dangerouslySetInnerHTML={{__html: projectContent}}/>
            </Tab>*/}
            <Tab eventKey="issues" title="Problēmas & uzlabojumi">
                <IssuesList key={`${projectId}-issues`} projectId={projectId}/>
            </Tab>
            {/*            <Tab eventKey="tasks" title="Uzdevumi">
                NOPE - nevar visu gribēt uzreiz.
            </Tab>*/}
            <Tab eventKey="work" title="Darbi">
                {showEditWorkEntry ? <Card className={`mb-3`}>
                        <Card.Body>
                            <FormGroup className={`mb-2`}>
                                <FormLabel htmlFor={`work-entry-title`} className={`mb-1`}>Nosaukums:</FormLabel>
                                <FormControl size={`sm`} type={`text`} id={`work-entry-title`} value={editWorkEntry.title} onChange={e => {
                                    setEditWorkEntry(
                                        prevState => ({
                                            ...prevState,
                                            title: e.target.value
                                        }))
                                }}/>
                            </FormGroup>
                            <Row className={`mb-2`}>
                                <Col xs={3}>
                                    <FormGroup>
                                        <FormLabel htmlFor={`work-entry-started`} className={`mb-1`}>Uzsākts:</FormLabel>
                                        <InputGroup size={`sm`}>
                                            <FormControl type={`datetime-local`} id={`work-entry-started`} value={editWorkEntry.started ? unixToDatetimeLocal(editWorkEntry.started) : ''} onChange={e => {
                                                setEditWorkEntry(prevState => ({
                                                    ...prevState,
                                                    started: Math.floor((new Date(e.target.value)).getTime() / 1000)
                                                }))
                                            }}/>
                                            <Button variant={`outline-secondary`} onClick={() => {
                                                let now = new Date();
                                                now.setSeconds(0);
                                                setEditWorkEntry(prevState => ({
                                                    ...prevState,
                                                    started: Math.floor(now.getTime() / 1000)
                                                }))
                                            }}>Tagad</Button>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col xs={3}>
                                    <FormGroup>
                                        <FormLabel htmlFor={`work-entry-ended`} className={`mb-1`}>Pabeigts:</FormLabel>
                                        <InputGroup size={`sm`}>
                                            <FormControl type={`datetime-local`} id={`work-entry-ended`} value={editWorkEntry.ended ? unixToDatetimeLocal(editWorkEntry.ended) : ''} onChange={e => {
                                                setEditWorkEntry(prevState => ({
                                                    ...prevState,
                                                    ended: Math.floor((new Date(e.target.value)).getTime() / 1000)
                                                }))
                                            }}/>
                                            <Button variant={`outline-secondary`} onClick={() => {
                                                let now = new Date();
                                                now.setSeconds(0);
                                                setEditWorkEntry(prevState => ({
                                                    ...prevState,
                                                    ended: Math.floor(now.getTime() / 1000)
                                                }))
                                            }}>Tagad</Button>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <FormLabel htmlFor={`edit-work-description`} className={`mb-1`}>Komentārs:</FormLabel>
                                <Editor
                                    id={`edit-work-description`}
                                    initialValue={editWorkEntry.initialDescription}
                                    onEditorChange={content => {
                                        setEditWorkEntry(prevState => ({
                                            ...prevState,
                                            description: content
                                        }))
                                    }}
                                    tinymceScriptSrc="/tinymce/tinymce.min.js"
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        language: 'lv',
                                        plugins:
                                            ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'autoresize', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table']
                                        ,
                                        toolbar: 'undo redo | formatselect | ' +
                                            'bold italic backcolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                        autoresize: true,
                                    }}
                                />
                            </FormGroup>
                        </Card.Body>
                        <Card.Footer className={`d-flex`}>
                            <Button size={`sm`} variant={`outline-secondary`} onClick={toggleEditWorkEntry}>Atcelt</Button>
                            <Button size={`sm`} variant={`outline-primary`} className={`ms-3`} onClick={saveWorkEntry} disabled={workItemSaving}>{workItemSaving ?
                                <Spinner size={`sm`}/> : 'Saglabāt'}</Button>
                            {editWorkEntry.id ?
                                <Button size={`sm`} variant={`outline-danger`} className={`ms-auto`} onClick={deleteWorkItem}>Dzēst</Button> : ''}
                        </Card.Footer>
                    </Card> :
                    <div className={`mb-3 d-flex justify-content-end`}>
                        <Button size={`sm`} variant={`outline-primary`} onClick={toggleEditWorkEntry.bind(null, 0)}>Pievienot
                            ierakstu</Button>
                    </div>
                }
                <Table size={`sm`} bordered={true} hover={true}>
                    <thead>
                    <tr>
                        <th style={{width: '1px'}}>ID</th>
                        <th>Nosaukums</th>
                        <th style={{width: '1px'}}>Sākts</th>
                        <th style={{width: '1px'}}>Pabeigts</th>
                        <th style={{width: '1px'}}>Ilgums</th>
                        <th style={{width: '1px'}}>Izpildītājs</th>
                    </tr>
                    </thead>
                    <tbody>
                    {workItems.map(item =>
                        <tr key={item.id} className={(!item.started || !item.ended ? 'table-danger' : '')}>
                            <td style={{whiteSpace: 'nowrap'}}>
                                <Button variant={`link`} className={`p-0`} onClick={e => {
                                    e.preventDefault();
                                    toggleEditWorkEntry(item.id, item);
                                }}>{item.id}</Button></td>
                            <td>{item.title}</td>
                            <td style={{whiteSpace: 'nowrap'}}>{item.started ? UTCToLocalTime(item.started) : ''}</td>
                            <td style={{whiteSpace: 'nowrap'}}>{item.ended ? UTCToLocalTime(item.ended) : ''}</td>
                            <td style={{whiteSpace: 'nowrap'}}>{item.started && item.ended ? formatDuration(item.started, item.ended) : ''}</td>
                            <td style={{whiteSpace: 'nowrap'}}>{item.user}</td>
                        </tr>)}
                    </tbody>
                </Table>
            </Tab>
        </Tabs>
        <ConfirmModal {...confirmModalProps}/>
    </Container>;
}

export default Project;
