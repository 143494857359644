function convertUrlsToLinks(htmlStr) {
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    const ignoredPatterns = /(src|href)=["'](\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])["']/ig;

    return htmlStr.replace(urlPattern, (match) => {
        // Check if the URL is within `src` or `href` attributes
        if (htmlStr.match(ignoredPatterns)) {
            return match; // Return the original match if within `src` or `href`
        }

        let safeUrl = match.startsWith('http') ? match : `http://${match}`;
        safeUrl = safeUrl.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
        return `<a href="${safeUrl}" target="_blank">${safeUrl}</a>`;
    });
}

export default convertUrlsToLinks;
