import {Container, Nav, Navbar as BootstrapNavbar} from "react-bootstrap";
import {Link} from "react-router-dom";
import CallCenter from "./CallCenter";
import SVGLogo from "./assets/images/logo-light.svg";
import {UserContext} from "./modules/users/UserContext";
import {useContext} from "react";

const Navbar = function (props) {

    const {authenticated, setAuthenticated} = useContext(UserContext);

    const logOut = () => {
        CallCenter.send('revoke_session').then(response => {
            setAuthenticated(response.data.authenticated);
        });
    };

    return (
        <BootstrapNavbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container>
                <BootstrapNavbar.Brand as={Link} to="/"><img src={SVGLogo} alt="iBurzumLV" style={{height: '24px'}}/></BootstrapNavbar.Brand>
                <BootstrapNavbar.Toggle aria-controls="responsive-navbar-nav"/>
                <BootstrapNavbar.Collapse id="responsive-navbar-nav">
                    {authenticated &&
                        <Nav>
                            <Nav.Link as={Link} to="/projects/?page=1">Projekti</Nav.Link>

                        </Nav>
                    }
                    <Nav className="ms-auto">
                        {authenticated ?
                            <>
                                <Nav.Link as={Link} to="/settings/account">
                                    Uzstādījumi
                                </Nav.Link>
                                <Nav.Link as={Link} onClick={logOut}>
                                    Iziet
                                </Nav.Link>
                            </> :
                            <Nav.Link as={Link} to="/login/">
                                Ienākt
                            </Nav.Link>
                        }
                    </Nav>
                </BootstrapNavbar.Collapse>
            </Container>
        </BootstrapNavbar>
    );
};

export default Navbar;
