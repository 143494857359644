import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {useContext, useEffect} from "react";
import {UserContext} from "./modules/users/UserContext";
import CallCenter from "./CallCenter";
import Navbar from "./Navbar";
import Home from "./Home";
import About from "./About";
import Loading from "./components/Loading";
import Projects from "./modules/projects/Projects";
import Project from "./modules/projects/Project";
import EditProject from "./modules/projects/EditProject";
import Login from "./Login";
import {Container} from "react-bootstrap";
import SVG404 from "./assets/images/404.svg";
import Issue from "./modules/issues/Issue";
import EditIssue from "./modules/issues/EditIssue";
import Account from "./modules/settings/Account";

export function Views(props) {
	const navigate                                   = useNavigate();
	const location                                   = useLocation();
	const {authenticated, setAuthenticated, setUser} = useContext(UserContext);

	const granted_Locations = [
		'/login/',
		'/loader-test/',
	];

	useEffect(() => {
		if (authenticated === null) {
			CallCenter.getAuthState().then(state => {
				setUser(CallCenter.getUserObject());
				setAuthenticated(state);
			});
		} else if (!authenticated && granted_Locations.indexOf(location.pathname) < 0) {
			navigate('/login/', {state: {from: location}});
		}
	}, [authenticated]);


	return <>
		<Navbar />
		<div className="flex-grow-1 position-relative d-flex pt-3">
			<Routes>
				{authenticated && <>
					<Route exact path="/" element={<Home />}></Route>
					<Route exact path="/about/" element={<About />}></Route>
					<Route exact path="/issues/new" element={<EditIssue />}></Route>
					<Route exact path="/issues/:issueId" element={<Issue />}></Route>
					<Route exact path="/issues/:issueId/edit" element={<EditIssue />}></Route>
					<Route exact path="/settings/" element={<Account />}></Route>
					<Route exact path="/settings/account" element={<Account />}></Route>
					<Route exact path="/projects/" element={<Projects />}></Route>
					<Route exact path="/projects/new" element={<EditProject />}></Route>
					<Route exact path="/projects/:projectId" element={<Project />}></Route>
					<Route exact path="/projects/:projectId/:tab" element={<Project />}></Route>
					<Route exact path="/projects/:projectId/edit" element={<EditProject />}></Route>
					<Route exact path="/login/" element={<Login />}></Route>
					<Route path="*" element={
						<Container>
							<div className="d-flex align-items-center justify-content-center position-relative h-100">
								<img src={SVG404} style={{height: '80vh'}} alt="" />
							</div>
						</Container>
					}></Route>
				</>
				}
				{!authenticated && <>
					<Route exact path="/" element={<Home />}></Route>
					<Route exact path="/login/" element={<Login />}></Route>
					<Route exact path="/loader-test/" element={<Loading />}></Route>
				</>
				}
			</Routes>
		</div>
	</>;
}
